import { qs, qsa } from "../utils/lib";

export function changeFormValue() {
  let minusBtn = qs(".js-changeFormValue .js-minusBtn");
  let plusBtn = qs(".js-changeFormValue .js-plusBtn");
  let inputNum = qs(".js-changeFormValue input");

  minusBtn.addEventListener("click", (evt) => {
    evt.preventDefault();

    if (inputNum.value <= 0) {
      return;
    } else {
      inputNum.value--;
    }
  });

  plusBtn.addEventListener("click", (evt) => {
    evt.preventDefault();
    inputNum.value++;
  });
}
