import { toggleHeading1 } from "simplemde"
import { qs,qsa } from "../utils/lib"
class NewsArchive {
    constructor(el) {
        this.page = qs('.js-news-archive')
        this.btns = qsa('button[data-input]')
        this.reset = qs('.js-reset')
        this.rows = qsa('tr[data-tags]')
        this.active_btn = ""
        this.active_tag = ""
        this.active_rows = []
    }
    init() {
        this.btns.forEach(btn => {
            btn.addEventListener('click', () => {
                this.active_tag = btn.dataset.input
                this.setFilters()
                this.setActive(btn)
                this.active_btn = btn
            })
        })
        this.reset.addEventListener('click', this.resetRows.bind(this))
    }
    setFilters = () => {
        let array = []
        this.rows.forEach(n => {
            JSON.parse(n.dataset.tags).forEach(tag => {
                if (tag == this.active_tag) {
                    this.active_rows.push(n)
                    array.push(n)   
                }
            })
        })
        console.log(array)
        this.animateRows(array)
    }
    setActive = (active) => {
        this.btns.forEach(btn => {
            if (active == btn) {
                btn.classList.add('active')
            } else {
                btn.classList.remove('active')
                this.reset.classList.remove('active')
            }
        })
    }
    resetRows() {
        this.rows.forEach(row => row.classList.remove('hidden'))
        this.btns.forEach(btn => btn.classList.remove('active'))
        this.reset.classList.add('active')
    }
    animateRows(array) {
        this.rows.forEach(row => row.classList.add('hidden'))
        array.forEach(item => {
            item.classList.remove('hidden')
        })
    }
}
export default NewsArchive