import "../../scss/application.scss";
import { qs, qsa } from "../utils/lib";
import { pageTransition } from "../components/barba";
import { navbar } from "../components/navbar";
import SmoothScroll from "../components/smoothScroll";
import Intersection from "../components/intersection";
import { parallaxTextAnimation } from "../components/animations";
import { changeFormValue } from "../components/form";
import NewsArchive from "../components/news_archive";

document.addEventListener("DOMContentLoaded", () => {
  pageTransition();
  mainFunction();
  const page = qs('.js-news-archive')
  if (page) {
    const arc = new NewsArchive(page);
    arc.init()
  }


});

export function mainFunction() {
  const inter = new Intersection();
  inter.init();

  const form = document.getElementById("contactform");
  if (form) {
    form.onsubmit = function (event) {
      event.preventDefault();
      const formData = new FormData(form);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", form.action, true);
      xhr.onload = function (e) {
        console.log(xhr);
        const response = JSON.parse(xhr.response);
        if (xhr.status === 200) {
          const btn = qs('.js_contact_form')
          btn.innerHTML = "Thank you";
          btn.classList.add("disabled")
        } else {

        }
      };
      xhr.send(formData);
    };
  }



  navbar();

  setTimeout(() => {
    //const smooth = new SmoothScroll();
    //smooth.init();
  }, 500);

  if (document.querySelector(".home-hero")) {
    changeFormValue();
    parallaxTextAnimation(".home-hero", [".home-hero span"]);
  }

  if (document.querySelector(".test")) {
    parallaxTextAnimation(".test", [".test-abs-txt-outer"]);
  }

  if (document.querySelector(".why-silk-scrollimg")) {
    parallaxTextAnimation(".why-silk-centertext", [".why-silk-imgs-cont"]);
  }
}
