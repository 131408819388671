import barba from "@barba/core";
import gsap from "gsap";
import { mainFunction } from "../packs/application";
import { parallaxTextAnimation } from "../components/animations";
import { changeFormValue } from "../components/form";

export function pageTransition() {
  const tl = gsap.timeline();

  barba.hooks.after(() => {
    mainFunction();
  });

  barba.init({
    cacheIgnore: ["/newsroom"],
    transitions: [
      {
        sync: false,

        before() {
          gsap.set(".page-transition-square", { clearProps: "all" });

          tl.to(".page-transition-square", {
            height: "100%",
            ease: "Expo.easeInOut",
            duration: 0.7,
          });
        },

        leave(data) {
          return tl.to(data.current.container, {
            opacity: 0,
          });
        },

        afterLeave(data) {
          window.scrollTo({ top: 0, behavior: "instant" });
        },

        enter() {
          tl.to(".page-transition-square", {
            top: "100%",
            ease: "Expo.easeInOut",
            duration: 0.7,
            onComplete: () => {
              gsap.to(".page-transition-square", {
                height: 0,
                autoAlpha: 0
              })
            }
          });
        },
      },
    ],
  });
}
