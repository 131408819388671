import gsap from "gsap";
import { qs, qsa } from "../utils/lib";
import { graphAnimation } from "../components/graphAnimation";
import { techGraphAnimation } from "../components/techGraphAnimation";

export default class Intersection {
  constructor() {
    this.target = Array.from(document.querySelectorAll("[data-animation]"));
    this.options = {
      rootMargin: "0px 0px -50px 0px",
    };
  }

  init() {
    this.setTargets();
    this.initObserver();
  }

  setTargets() {
    this.target.forEach((el) => {
      if (el.dataset.animation === "text") {
        gsap.set(el, {
          opacity: 0,
          y: 20,
          duration: 0,
        });
      }
      if (el.dataset.animation === "img") {
        gsap.set(el, {
          opacity: 0,
          duration: 0,
        });
      }
      if (el.dataset.animation === "scroll") {
        gsap.set(el, {
          opacity: 0,
          x: el.dataset.direction,
          duration: 0,
        });
      }

      if (el.dataset.animation === "graph") {
        gsap.set(el, {
          opacity: 0,
          duration: 0,
        });
      }

      if (el.dataset.animation === "tech_graph") {
        gsap.set(el, {
          opacity: 0,
          duration: 0,
        });
      }

      if (el.dataset.animation === "gif") {
        gsap.set(el, {
          opacity: 0,
          duration: 0,
        });
      }
    });
  }

  
  animation(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (entry.target.dataset.animation === "text") {
          gsap.to(entry.target, {
            opacity: 1,
            y: 0,
            duration: 1,
          });
        }

        if (entry.target.dataset.animation === "img") {
          entry.target.addEventListener("load", () => {
            gsap.to(entry.target, {
              opacity: 1,
              ease: "Expo.out",
              duration: 0.5,
            });
          });
          entry.target.setAttribute(
            "src",
            entry.target.getAttribute("alt-src")
          );
        }
        if (entry.target.dataset.animation === "img_zoom") {
          entry.target.addEventListener("load", () => {
            gsap.to(entry.target, {
              opacity: 1,
              ease: "Expo.out",
              duration: 0.5,
              onComplete: () => {
                gsap.to(entry.target, {
                  scale: 1.1,
                  ease: "Expo.InOut",
                  duration: 3,
                  delay: 1
                })
              }
            });
            
          });
          entry.target.setAttribute(
            "src",
            entry.target.getAttribute("alt-src")
          );
        }

        if (entry.target.dataset.animation === "scroll") {
          entry.target.addEventListener("load", () => {
            gsap.fromTo(entry.target, {
              scale: 0.9
            }
              ,{
              opacity: 1,
              x: 0,
              scale: 1,
              ease: "Expo.out",
              duration: 1,
            });
          });
          entry.target.setAttribute(
            "src",
            entry.target.getAttribute("alt-src")
          );
        }

        if (entry.target.dataset.animation === "graph") {
          setTimeout(function() {
            graphAnimation(entry.target)
          }, 100)
          
        }

        if (entry.target.dataset.animation === "tech_graph") {
          techGraphAnimation(entry.target)
        }

        // if (entry.target.dataset.animation === "video") {
        //   entry.target.play()
        // }

        if (entry.target.dataset.animation === "gif") {
          entry.target.addEventListener("load", () => {
            gsap.to(entry.target, {
              opacity: 1,
              ease: "Expo.out",
              duration: 0.5,
            });
          });
          entry.target.setAttribute(
            "src",
            entry.target.getAttribute("alt-src")
          );
        }

        
          this.observer.unobserve(entry.target);
        
        
      }
    });
  }

  initObserver() {
    this.observer = new IntersectionObserver(
      this.animation.bind(this),
      this.options
    );
    this.target.forEach((el) => {
      this.observer.observe(el);
    });
  }


  
}
