import gsap from "gsap";


export function graphAnimation(el) {

    const rects = Array.from(el.querySelectorAll("rect"))
    const year = el.querySelector("p")

    gsap.to(el, {
      opacity: 1,
      duration: 1,
    });
    gsap.to(year, {
      opacity: 0,
      duration: 0
    })
    
    
    rects.forEach((rect, i) => {
      const height = rect.height.baseVal.value
      const col_year = rect.dataset.year
      gsap.set(rect,{
        opacity: 0,
        duration: 0,
        attr:{height: "0"}
      })
      if (col_year === "2017") {
        gsap.to(rect, {
          opacity: 1,
          attr:{height: height},
          duration: .5,
          delay: (0.05 * i),
          ease: "Expo.easeInOut",
        })
        gsap.to(year,{
          opacity: 1, 
          duration: .6,
          delay: (0.05 * i),
          ease: "Expo.easeInOut",
        })
      } else {
        gsap.to(rect,{
          opacity: 0.5,
          duration: .6,
          attr:{height: height},
          delay: (0.05 * i),
          ease: "Expo.easeInOut",
        })
      }
      
      rect.addEventListener("mouseenter", () => {
        const col_year = rect.dataset.year
        rects.forEach((r) => {
          gsap.to(r, {
            opacity: 0.5, 
            duration: 0
          })
        })
        gsap.to(rect, {
          opacity: 1,
          duration: 0
        })
        year.innerHTML = col_year
      })

    })
    
  }