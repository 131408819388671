import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

gsap.config({ nullTargetWarn: false });

export function parallaxTextAnimation(trigger, els) {
  gsap.defaults({ overwrite: "auto" });

  ScrollTrigger.create({
    trigger: trigger,
    start: "top center",
    onUpdate: (self) => {
      const velocity = self.getVelocity();
      if (velocity > 1) {
        els.forEach((el, i) => {
          gsap.to(el, { y: -20 * (i + 1), duration: 1.5 });
        });
      } else if (velocity < -1) {
        els.forEach((el, i) => {
          gsap.to(el, { y: 20 * (i + 1), duration: 1.5 });
        });
      }
    },
  });
}
