import gsap from "gsap";


export function techGraphAnimation(el) {

    const rects = Array.from(el.querySelectorAll(".target"))
    const year = el.querySelector("p")

    gsap.to(el, {
      opacity: 1,
      duration: 1,
    });
    gsap.to(year, {
      opacity: 0,
      duration: 0
    })
    
    rects.forEach((rect, i) => {
      const width = rect.width.baseVal.value
      gsap.set(rect,{
        opacity: 0,
        duration: 0,
        attr:{width: "0"}
      })
      gsap.to(rect, {
        opacity: 0.8,
        attr:{width: width},
        duration: 1.3,
        delay: (0.4 * i),
        ease: "Expo.easeInOut",
      })
      
    })
    
  }