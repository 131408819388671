import gsap from "gsap";
import { qs, qsa } from "../utils/lib";

export function navbar() {
  if (qs(".newsroom") || qs(".article") || qs('.js-news-archive')){
    qs(".navbar").classList.add("active");
    qs(".navbar-logo img").src = "/svg/logob.svg";
    qs(".navbar-hamburger img").src = "/svg/hamburger.svg";
  } else {
    qs(".navbar").classList.remove("active");
    qs(".navbar-logo img").src = "/svg/logo.svg";
    qs(".navbar-hamburger img").src = "/svg/hamburger-w.svg";
  }

  window.onscroll = () => scrollFunction();

  const scrollFunction = () => {
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      if (qs(".newsroom") || qs(".article")) {
        qs(".navbar").classList.add("active");
        qs(".navbar-logo img").src = "/svg/logob.svg";
      } else {
        qs(".navbar").classList.add("active");
        qs(".navbar-logo img").src = "/svg/logob.svg";
        qs(".navbar-hamburger img").src = "/svg/hamburger.svg";
      }
    } else {
      if (qs(".newsroom") || qs(".article")  || qs('.js-news-archive')) {
        return;
      } else {
        qs(".navbar").classList.remove("active");
        qs(".navbar-logo img").src = "/svg/logo.svg";
        qs(".navbar-hamburger img").src = "/svg/hamburger-w.svg";
      }
    }
  };

  // menu mobile
  if (window.innerWidth < 960) {
    const open_menu = qs(".navbar-hamburger");
    const close_menu = qs(".navbar-mobile-close");
    const menu_mobile = qs(".navbar-mobile");
    const menu_inner = qs(".navbar-mobile-cont");
    const menu_transparent = qs(".navbar-mobile-transparent");
    const links = qsa(".navbar-mobile-links a");
    open_menu.addEventListener("click", () => {
      menu_mobile.classList.add("navbar-mobile-open");
      chooseLogo();
      openMenu(menu_inner);
    });
    close_menu.addEventListener("click", () => {
      closeMenu(menu_inner, menu_mobile);
    });
    menu_transparent.addEventListener("click", () => {
      closeMenu(menu_inner, menu_mobile);
    });
    linksBehaviour(links, menu_inner, menu_mobile);
  }

  function linksBehaviour(links, menu_inner, menu_mobile) {
    const activePage = window.location.pathname;
    links.forEach((link) => {
      const path = link.dataset.path;
      if (path == activePage) {
        link.classList.add("navbar-active-link");
      }
      link.addEventListener("click", () => {
        links.forEach((l) => {
          l.classList.remove("navbar-active-link");
        });
        link.classList.add("navbar-active-link");
        setTimeout(function () {
          closeMenu(menu_inner, menu_mobile);
        }, 100);
      });
    });
  }

  function chooseLogo() {
    if (document.body.scrollTop == 0) {
      qs(".navbar-logo img").src = "/svg/k-white.svg";
    }
    if (
      document.body.scrollTop > 0 ||
      document.documentElement.scrollTop > 0 ||
      qs(".newsroom") ||
      qs(".article")
    ) {
      qs(".navbar-logo img").src = "/svg/logob.svg";
    }
  }

  function openMenu(target) {
    gsap.fromTo(
      target,
      {
        x: 100,
        duration: 0,
      },
      {
        x: 0,
        duration: 0.5,
      }
    );
  }

  function closeMenu(target, menu) {
    gsap.to(target, {
      x: "100%",
      duration: 0.4,
      onComplete: () => {
        menu.classList.remove("navbar-mobile-open");
      },
    });
  }
}
